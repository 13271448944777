// Bootstrap 4 overrides
$table-hover-bg:              rgba(#007bff, .075);

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 1240px,
  lg: 1430px,
  xl: 1800px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  md: 1200px,
  lg: 1400px,
  xl: 1600px
);

// @include media-breakpoint-down(md) {
//     .container {
//         width: 100%;
//         max-width: none;
//     }
// }

// Imports
@import '~bootstrap/scss/bootstrap.scss';
$fa-font-path: "~font-awesome/fonts" !default;
@import '~font-awesome/scss/font-awesome';

// AMPP Variables
$table-selected-row-bg: #add4ff;
$table-analysis-row-bg: #17a2b8;

.container 
{
    font-size: small;
}
.container-fluid
{
    font-size: small;
}

// Table customizations

.table th {
    border-top: none;
}

.table tbody tr.analysisCommunity {
    background-color: rgba($table-analysis-row-bg, .075);
    border-top: 3px solid $table-analysis-row-bg;
    border-bottom: 3px solid $table-analysis-row-bg;
}

.table-hover tbody tr.is-selected:hover td,
.table-hover tbody tr.is-selected:hover th,
.table-hover tbody tr.is-selected,
.table-hover tbody tr.active:hover td,
.table-hover tbody tr.active:hover th,
.table-hover tbody tr.active {
    background-color: $table-selected-row-bg;
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    //background-color: lightsteelblue;
    -webkit-transition: 500ms all;
    transition: 500ms all;
}

.table-scroll thead {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.table-scroll tbody {
    max-height: 150px;
    overflow-y: auto;
    display: block;
    width: 100%;
    table-layout: fixed;
}

.table-scroll tr {
    display: table;
    table-layout: fixed;
    width: 100%;
}

input[type=checkbox] {
    opacity: 0;
  }
           
  input[type=checkbox] + label {
      background: #fff;
      border: 1px solid #999;
      height: 25px;
      width: 25px;
      display:inline-block;
      padding: 0 0 0 0px;
  }
  
  input[type=checkbox]:checked + label {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><style type='text/css'>.svgfill {fill:#fff;}</style><path class='svgfill' d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'/></svg>");
      background-size: 80% 80%;
      background-repeat: no-repeat;
      background-position: center;
  }
  
  input[type=checkbox] + label span {
      padding-left: 30px;
  }
  
input[type=radio] {
    opacity: 0;
  }
           
  input[type=radio] + label {
      border-radius: 50%;
      background: #fff;
      border: 1px solid #999;
      height: 25px;
      width: 25px;
      display:inline-block;
      padding: 0 0 0 0px;
  }
  
  input[type=radio]:checked + label {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><style type='text/css'>.svgfill {fill:#fff;}</style><path class='svgfill' d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z'/></svg>");
      background-size: 80% 80%;
      background-repeat: no-repeat;
      background-position: center;
  }
  
  input[type=radio] + label span {
      padding-left: 30px;
  }

/* Allow browser to print in portrait or landscape (Chrome) */
@page { size: auto; }
